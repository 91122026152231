<template>
  <div class="local">
    <Nav></Nav>
    <div class="local-con desktop-present">
      <div class="local-title">
        <div class="text">
          <div v-if="showChange">
            当前位置：
            <span
              >首页 - 图库 - {{ fixtopicList[fixtopicIndex].name }} - ****</span
            >
          </div>
          <div v-if="!showChange">
            当前位置： <span>首页 - 图库 - 地区 - {{ currentName }}</span>
          </div>

          <!-- {{ localName }}目前共有稿件:{{ postCount }}
          <span style="margin-left: 20px"> 图片数: {{ postPicCount }}</span> -->
        </div>
        <div class="page">
          <div class="page-container">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="21"
              :hide-on-single-page="true"
              layout="prev, pager, next, jumper"
              :total="downTotal"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div class="local-list">
        <div class="local-left">
          <!-- <div class="fixtopic">
            <div class="fixtopic-title">栏目分类：</div>
            <div class="fixtopic-list">
              <el-collapse v-model="activeName" accordion>
                <el-collapse-item
                  v-for="(k, fixtopicIndex) in fixtopicList"
                  :key="'fix_' + k.id"
                  :name="k.id"
                  :title="k.name"
                >
                  <div v-for="(h, subsIndex) in k.subs" :key="'subs_' + h.id">
                    {{ h.name }}
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div> -->
          <div class="local-area">
            <div
              class="local-name"
              :class="{ grey: clickIndex == 100 }"
              @click="onGoReset"
            >
              吴江区:
            </div>
            <div class="local-con">
              <div
                class="local-del"
                v-for="(k, shiIndex) in typeRootList"
                :key="k.id"
              >
                <div
                  class="local-shi"
                  :class="{ red: clickIndex == k.id }"
                  @click="onLocal(k.id, k.name)"
                >
                  {{ k.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="local-right">
          <div class="search">
            <el-input v-model="keyWord"></el-input>
            <div class="search" @click="onSearchKey(keyWord)">搜索</div>
          </div>
          <div class="post-list">
            <div v-for="p in postList" :key="p.id" class="whole_box">
              <div class="whole_box_content" @click="onLookPost(p.id)">
                <div
                  class="img"
                  :style="{ backgroundImage: `url(` + p.imageURL + `)` }"
                ></div>
                <div class="title_s">
                  {{ p.title }}
                </div>
                <!-- <div class="eventTime">{{ p.eventTime }}</div> -->
              </div>
            </div>
            <div class="page">
              <div class="page-container">
                <el-pagination
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage"
                  :page-size="21"
                  :hide-on-single-page="true"
                  layout="prev, pager, next, jumper"
                  :total="downTotal"
                ></el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="local-con mobile-present">
      <div class="local-title">
        <div class="text">
          <div v-if="showChange">
            当前位置：
            <span
              >首页 - 图库 - {{ fixtopicList[fixtopicIndex].name }} -
              {{ fixtopicList[fixtopicIndex].subs[subsIndex].name }}</span
            >
          </div>
          <div v-if="!showChange">
            当前位置： <span>首页 - 图库 - 地区 - {{ currentName }}</span>
          </div>

          <!-- {{ localName }}目前共有稿件:{{ postCount }}
          <span style="margin-left: 20px"> 图片数: {{ postPicCount }}</span> -->
        </div>
        <div class="page">
          <div class="page-container">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="21"
              :hide-on-single-page="true"
              layout="prev, pager, next, jumper"
              :total="downTotal"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div class="local-list">
        <div class="local-left">
          <div class="fixtopic">
            <div class="fixtopic-title">栏目分类：</div>
            <div class="fixtopic-list">
              <el-collapse v-model="activeName" accordion>
                <el-collapse-item
                  v-for="(k, fixtopicIndex) in fixtopicList"
                  :key="'fix_' + k.id"
                  :name="k.id"
                  :title="k.name"
                >
                  <div
                    v-for="(h, subsIndex) in k.subs"
                    :key="'subs_' + h.id"
                    @click="onSearchKey(h.name, fixtopicIndex, subsIndex)"
                  >
                    {{ h.name }}
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
          <div class="local-area">
            <div
              class="local-name"
              :class="{ red: clickIndex == 100 }"
              @click="onGoReset"
            >
              吴江:
            </div>
            <div class="local-con">
              <el-collapse v-model="activeLocalName" accordion>
                <el-collapse-item style="font-size: 18px" title="吴江" name="1">
                  <div @click="onLocal(100, '吴江区')">吴江区</div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
        <div class="local-right">
          <div class="search">
            <el-input v-model="keyWord"></el-input>
            <div class="search" @click="onSearchKey(keyWord)">搜索</div>
          </div>
          <div class="post-list">
            <div v-for="p in postList" :key="p.id" class="whole_box">
              <div class="whole_box_content">
                <div
                  class="img"
                  :style="{ backgroundImage: `url(` + p.imgURL + `)` }"
                  @click="onLookPost(p.id)"
                ></div>
                <div class="title_s" @click="onLookPost(p.id)">
                  {{ p.title }}
                </div>
                <!-- <div class="eventTime">{{ p.eventTime }}</div> -->
              </div>
            </div>
            <div class="page">
              <div class="page-container">
                <el-pagination
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage"
                  :page-size="21"
                  :hide-on-single-page="true"
                  layout="prev, pager, next, jumper"
                  :total="downTotal"
                ></el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      keyWord: "", // 搜索关键字
      activeName: "", //手风琴效果
      activeLocalName: "1", // 地区手风琴
      typeRootList: [], // 地址的root
      defaultProps: {
        label: "name",
        children: "children",
      },
      postList: [], // 稿件列表
      page: 1,
      currentPage: 1,
      downTotal: 1,
      postCount: "", // 当前的稿件数
      postPicCount: "", // 当前的图片数
      query: {},
      dropShow: false, // 下拉加载展示
      clickId: "", // 被点击的ID
      clickIndex: 100, // 未被点击
      clickxian: -1,
      localName: "湖州地区",
      currentName: "吴江区",
      fixtopicIndex: 0,
      subsIndex: 0,
      showChange: false,
      fixtopicList: [
        {
          name: "政务活动",
          id: 1,
          subs: [
            {
              name: "会议**",
              id: 1,
            },
            {
              name: "活动**",
              id: 2,
            },
            {
              name: "外事***",
              id: 3,
            },
            {
              name: "时政**",
              id: 4,
            },
          ],
        },
        {
          name: "社会人文",
          id: 2,
          subs: [
            {
              name: "文化遗产",
              id: 5,
            },
            {
              name: "民间工艺",
              id: 6,
            },
            {
              name: "浙北人物",
              id: 7,
            },
            {
              name: "百姓生活",
              id: 8,
            },
            {
              name: "慈善公益",
              id: 9,
            },
            {
              name: "教育科技",
              id: 10,
            },
            {
              name: "医疗卫生",
              id: 11,
            },
            {
              name: "军事民兵",
              id: 12,
            },
            {
              name: "会展活动",
              id: 13,
            },
          ],
        },
        {
          name: "文化体育",
          id: 3,
          subs: [
            {
              name: "绘画书法",
              id: 14,
            },
            {
              name: "体育竞技",
              id: 15,
            },
            {
              name: "戏曲表演",
              id: 16,
            },
            {
              name: "建筑景观",
              id: 17,
            },
          ],
        },
        {
          name: "经济发展",
          id: 4,
          subs: [
            {
              name: "工业",
              id: 18,
            },
            {
              name: "旅游",
              id: 19,
            },
            {
              name: "水利",
              id: 20,
            },
            {
              name: "环保",
              id: 21,
            },
            {
              name: "城乡建设",
              id: 22,
            },
            {
              name: "信息",
              id: 23,
            },
            {
              name: "金融",
              id: 24,
            },
            {
              name: "交通",
              id: 25,
            },
            {
              name: "农业",
              id: 26,
            },
            {
              name: "餐饮",
              id: 27,
            },
          ],
        },
        {
          name: "老照片专题",
          id: 5,
          subs: [
            {
              name: "城乡变迁",
              id: 28,
            },
            {
              name: "经济建设",
              id: 29,
            },
            {
              name: "上山下乡",
              id: 30,
            },
          ],
        },
        {
          name: "艺术新锐",
          id: 6,
          subs: [
            {
              name: "艺术",
              id: 31,
            },
            {
              name: "新锐",
              id: 32,
            },
            {
              name: "人像",
              id: 33,
            },
            {
              name: "艺术摄影",
              id: 34,
            },
          ],
        },
      ],
    };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      var thiz = this;
      RD.addr()
        .id(100)
        .downAddress()
        .then((data) => {
          data.forEach((k) => {
            k.children = [];
            k.dropShow = false;
          });
          data.xianList = [];
          thiz.typeRootList = data;
        });
      if (thiz.$route.query.id) {
        thiz.onLocal(thiz.$route.query.id, thiz.$route.query.name);
      } else {
        thiz.queryPost(100);
      }
    },
    onGoReset() {
      this.reload();
      this.clickIndex = 100;
    },
    onClickShi(id) {
      this.queryPost(id);
    },
    queryPost(id) {
      var thiz = this;
      var post = {};
      post.addrId = id;
      post.storeIds = [1];
      RD.pure()
        .query()
        .query(post, thiz.page, 21)
        .then((items) => {
          items.list.forEach((i) => {
            i.imageURL = RD.pic().id(i.frontPicId).mImage_URL();
          });
          thiz.postCount = items.pager.total;
          thiz.postList = items.list;
          thiz.currentPage = items.pager.currentPage;
          thiz.downTotal = items.pager.total;
        });
      RD.query()
        .queryPic(post)
        .then((data) => {
          console.log("pic123", data);
          thiz.postPicCount = data.pager.total;
        });
    },
    handleCurrentChange(val) {
      var thiz = this;
      thiz.page = val;
      thiz.currentPage = val;
      thiz.queryPost(thiz.clickIndex);
    },
    onLookPost(postId) {
      this.$router.push({
        path: "/post.html",
        query: { id: postId },
      });
    },
    // 点击城市切换右边稿件
    onLocal(adddId, name) {
      var thiz = this;
      thiz.currentName = name;
      thiz.clickIndex = adddId;
      thiz.showChange = false;
      var post = {};
      post.addrId = adddId;
      post.storeIds = [1];
      RD.pure()
        .query()
        .query(post, thiz.page, 21)
        .then((items) => {
          items.list.forEach((i) => {
            i.imageURL = RD.pic().id(i.frontPicId).mImage_URL();
          });
          thiz.postCount = items.pager.total;
          thiz.postList = items.list;
          thiz.currentPage = items.pager.currentPage;
          thiz.downTotal = items.pager.total;
        });
    },
    // 按关键字搜索
    onSearchKey(keyWord, fixtopicIndex, subsIndex) {
      var thiz = this;
      thiz.showChange = true;
      thiz.fixtopicIndex = fixtopicIndex;
      thiz.subsIndex = subsIndex;
      var post = {};
      post.keyword = keyWord;
      (post.order = "0"), (post.keyInMain = true), (post.storeIds = [1]);
      RD.pure()
        .query()
        .query(post, thiz.page, 21)
        .then((items) => {
          items.list.forEach((i) => {
            i.imageURL = RD.pic().id(i.frontPicId).mImage_URL();
          });
          thiz.postCount = items.pager.total;
          thiz.postList = items.list;
          thiz.currentPage = items.pager.currentPage;
          thiz.downTotal = items.pager.total;
        });
    },
  },
};
</script>

<style scoped lang="less">
.local {
  height: auto;
  background-color: #f7f8fa;
  .local-con.desktop-present {
    width: 120rem;
    margin: 0 auto;
    min-height: 58.5rem;
    .local-title {
      height: 5rem;
      display: flex;
      position: relative;
      .text {
        text-align: left;
        width: 100%;
        clear: both;
        height: 40px;
        vertical-align: middle;
        line-height: 40px;
        font-size: 18px;

        span {
          color: #949496;
        }
      }
      .page {
        position: absolute;
        right: 3rem;
        top: 1rem;
      }
    }
    .local-list {
      display: flex;
      height: auto;
      .local-left {
        width: 25%;
        border: 0.1rem solid #ccc;
        overflow: hidden;
        .fixtopic {
          text-align: left;
          .fixtopic-title {
            background: #fff;
            color: #000;
            padding: 10px 40px 0 20px;
            height: 45px;
            line-height: 45px;
            cursor: pointer;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            position: relative;
            border: 1px solid #b8b8b8;
            font-size: 18px;
          }
          .fixtopic-list {
            .el-collapse {
              width: 310px;
            }
            ::v-deep .el-collapse-item__header {
              font-size: 16px;
              padding: 0px 20px;
            }
            ::v-deep .el-collapse-item__content {
              padding: 0px 40px;
              line-height: 40px;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
        .local-area {
          text-align: left;
          .local-name {
            background: #fff;
            color: #000;
            padding: 10px 40px 0 20px;
            height: 45px;
            line-height: 45px;
            cursor: pointer;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            position: relative;
            border: 1px solid #b8b8b8;
            font-size: 18px;
          }
          .grey {
            background-color: #ccc;
            color: #000;
          }
          .local-con {
            height: 100%;
            width: 100%;
            font-size: 20px;
            line-height: 40px;
            background-color: #fff;
            .local-del {
              height: auto;
              width: 100%;
              height: 4rem;
              line-height: 4rem;
              overflow: hidden;
              .local-shi {
                height: 4rem;
                text-align: left;
                cursor: pointer;
                padding: 0 1rem;
              }
              .local-shi.red {
                color: #fff;
                background-color: #d42d26;
              }
              .loca-xian {
                height: auto;
                margin-left: 2rem;
                text-align: left;
                .xian-list {
                  height: 3rem;
                  cursor: pointer;
                }
                .xian-list.red {
                  color: red;
                }
              }
            }
            .local-del:hover {
              .local-shi {
                color: #fff;
                background-color: #d42d26;
              }
            }
            .el-collapse {
              width: 310px;
            }
            ::v-deep .el-collapse-item__header {
              font-size: 16px;
              padding: 0px 20px;
            }
            ::v-deep .el-collapse-item__content {
              padding: 0px 40px;
              line-height: 40px;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
      }
      .local-right {
        width: 75%;
        .search {
          display: flex;
          ::v-deep .el-input__inner {
            width: 750px;
            height: 52px;
            margin-left: 10px;
          }
          .search {
            display: inline-block;
            height: 50px;
            width: 159px;
            margin: 0px;
            border: 1px solid #b8b8b8;
            background-color: #ff8c00;
            color: #fff;
            font-size: 22px;
            line-height: 50px;
            text-align: center;
            cursor: pointer;
          }
        }
        .post-list {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .whole_box {
            width: 27.8rem;
            height: 19.2rem;
            box-sizing: border-box;
            margin: 1rem;
            position: relative;
            box-shadow: 0.4rem 0.4rem 0.6rem rgba(0, 0, 0, 0.3);
            background-color: #f1f1f1;
            overflow: hidden;
            .whole_box_content {
              height: 100%;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              .img {
                width: 100%;
                height: 100%;
                transition: all 0.6s;
                display: block;
                z-index: 10;
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: contain;
                background-color: #f6f6f6;
                cursor: pointer;
              }
              .title_s {
                position: absolute;
                height: 45px;
                transition: all 0.5s linear;
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-align: left;
                font-size: 1.8rem;
                left: 0;
                bottom: 0px;
                line-height: 4.5rem;
                padding-left: 5px;
                cursor: pointer;
                background-color: rgba(255, 255, 255, 0.7);
                font-family: "Microsoft Yahei", "Simsun";
              }
              .eventTime {
                height: 3rem;
                line-height: 3rem;
                text-align: left;
                margin-left: 1rem;
              }
              .time {
                height: 10%;
                position: absolute;
                left: 13.5rem;
                .count {
                  height: 2rem;
                  display: inline-block;
                }
              }
            }
          }
          .page {
            position: relative;
            height: 6rem;
            width: 100%;
            .page-container {
              position: absolute;
              bottom: 1rem;
              right: 2rem;
            }
          }
        }
      }
    }
  }
  .local-con.mobile-present {
    width: 750px;
    margin: 0 auto;
    min-height: 58.5rem;
    .local-title {
      height: 5rem;
      display: flex;
      position: relative;
      .text {
        text-align: left;
        width: 100%;
        clear: both;
        height: 40px;
        vertical-align: middle;
        line-height: 40px;
        font-size: 18px;

        span {
          color: #949496;
        }
      }
      .page {
        position: absolute;
        right: 3rem;
        top: 1rem;
      }
    }
    .local-list {
      display: flex;
      height: auto;
      .local-left {
        width: 25%;
        border: 0.1rem solid #ccc;
        overflow: hidden;
        .fixtopic {
          text-align: left;
          .fixtopic-title {
            background: #fff;
            color: #000;
            padding: 10px 40px 0 20px;
            height: 45px;
            line-height: 45px;
            cursor: pointer;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            position: relative;
            border: 1px solid #b8b8b8;
            font-size: 18px;
          }
          .fixtopic-list {
            .el-collapse {
              width: 310px;
            }
            ::v-deep .el-collapse-item__header {
              font-size: 16px;
              padding: 0px 20px;
            }
            ::v-deep .el-collapse-item__content {
              padding: 0px 40px;
              line-height: 40px;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
        .local-area {
          text-align: left;
          .local-name {
            background: #fff;
            color: #000;
            padding: 10px 40px 0 20px;
            height: 45px;
            line-height: 45px;
            cursor: pointer;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            position: relative;
            border: 1px solid #b8b8b8;
            font-size: 18px;
          }
          .local-con {
            height: 100%;
            width: 90%;
            font-size: 20px;
            line-height: 40px;

            background-color: #fff;
            .local-del {
              height: auto;
              width: 80%;
              .local-shi {
                height: 3rem;
                text-align: left;
                cursor: pointer;
              }
              .local-shi.red {
                color: red;
              }
              .loca-xian {
                height: auto;
                margin-left: 2rem;
                text-align: left;
                .xian-list {
                  height: 3rem;
                  cursor: pointer;
                }
                .xian-list.red {
                  color: red;
                }
              }
            }
            .el-collapse {
              width: 310px;
            }
            ::v-deep .el-collapse-item__header {
              font-size: 16px;
              padding: 0px 20px;
            }
            ::v-deep .el-collapse-item__content {
              padding: 0px 40px;
              line-height: 40px;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
      }
      .local-right {
        width: 75%;
        .search {
          display: flex;
          ::v-deep .el-input__inner {
            width: 750px;
            height: 52px;
            margin-left: 10px;
          }
          .search {
            display: inline-block;
            height: 50px;
            width: 159px;
            margin: 0px;
            border: 1px solid #b8b8b8;
            background-color: #ff8c00;
            color: #fff;
            font-size: 22px;
            line-height: 50px;
            text-align: center;
            cursor: pointer;
          }
        }
        .post-list {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .whole_box {
            width: 27.8rem;
            height: 19.2rem;
            box-sizing: border-box;
            margin: 1rem;
            position: relative;
            box-shadow: 0.4rem 0.4rem 0.6rem rgba(0, 0, 0, 0.3);
            background-color: #f1f1f1;
            overflow: hidden;
            .whole_box_content {
              height: 100%;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              .img {
                width: 100%;
                height: 100%;
                transition: all 0.6s;
                display: block;
                z-index: 10;
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: contain;
                background-color: #f6f6f6;
                cursor: pointer;
              }
              .title_s {
                position: absolute;
                height: 45px;
                transition: all 0.5s linear;
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-align: left;
                font-size: 1.8rem;
                left: 0;
                bottom: 0px;
                line-height: 4.5rem;
                padding-left: 5px;
                cursor: pointer;
                background-color: rgba(255, 255, 255, 0.7);
                font-family: "Microsoft Yahei", "Simsun";
              }
              .eventTime {
                height: 3rem;
                line-height: 3rem;
                text-align: left;
                margin-left: 1rem;
              }
              .time {
                height: 10%;
                position: absolute;
                left: 13.5rem;
                .count {
                  height: 2rem;
                  display: inline-block;
                }
              }
            }
          }
          .page {
            position: relative;
            height: 6rem;
            width: 100%;
            .page-container {
              position: absolute;
              bottom: 1rem;
              right: 2rem;
            }
          }
        }
      }
    }
  }
}
.el-tree {
  height: 60rem;
  .el-tree-node {
    height: 8rem !important;
    display: inline-block;
  }
}
.addr {
  display: inline-block;
  font-size: 1.5rem;
}
</style>
